import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../config";

function ClientList() {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    // Realiza una petición GET a la ruta del backend para obtener los clientes
    axios
      .get(`${config.apiUrl}/api/clients`)
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener los clientes:", error);
      });
  }, []);

  return (
    <div>     
      <ul>
        {clients.map((client) => (
          <li key={client._id}>
            {client.name} - {client.email}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ClientList;
