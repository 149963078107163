// src/components/InvoiceList.js
import React, { useState, useEffect } from 'react';

function InvoiceList() {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    setInvoices([
      { id: 1, clientName: 'Cliente 1', amount: 100 },
      { id: 2, clientName: 'Cliente 2', amount: 200 }
    ]);
  }, []);

  return (
    <div>
      <h2>Lista de Facturas</h2>
      <ul>
        {invoices.map(invoice => (
          <li key={invoice.id}>Factura {invoice.id}: {invoice.clientName} - ${invoice.amount}</li>
        ))}
      </ul>
    </div>
  );
}

export default InvoiceList;
