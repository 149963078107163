// src/App.js
import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Clients from './pages/Clients';
import Invoices from './pages/Invoices';
import Login from './components/Login';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate(); // Hook de navegación

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthenticated(true);
    }

    // Función para bloquear el clic derecho
    const disableRightClick = (e) => e.preventDefault();
    
    // Función para bloquear la selección de texto
    const disableTextSelection = (e) => e.preventDefault();

    // Agregar los eventos para bloquear clic derecho y selección de texto
    document.addEventListener('contextmenu', disableRightClick);
    document.addEventListener('selectstart', disableTextSelection);

    // Limpiar los eventos cuando el componente se desmonte
    return () => {
      document.removeEventListener('contextmenu', disableRightClick);
      document.removeEventListener('selectstart', disableTextSelection);
    };
  }, []);

  // Función para manejar el logout
  const handleLogout = () => {
    // Eliminar el token de localStorage
    localStorage.removeItem('token');
    setAuthenticated(false);
    navigate('/'); // Redirigir al login después de cerrar sesión
  };

  return (
    <div>
      {!authenticated ? (
        <Login setAuthenticated={setAuthenticated} />
      ) : (
        <>
          <nav>
            <Link to="/">Inicio</Link>
            <Link to="/clientes">Clientes</Link>
            <Link to="/facturas">Facturas</Link>
          </nav>
          <button onClick={handleLogout}>Cerrar sesión</button>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/clientes" element={<Clients />} />
            <Route path="/facturas" element={<Invoices />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
