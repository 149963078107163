// src/pages/Invoices.js
import React from 'react';
import InvoiceList from '../components/InvoiceList';
import InvoiceForm from '../components/InvoiceForm';

function Invoices() {
  return (
    <div>
      <h1>Facturas</h1>
      <InvoiceList />
      <InvoiceForm />
    </div>
  );
}

export default Invoices;
