// src/components/InvoiceForm.js
import React, { useState } from 'react';

function InvoiceForm() {
  const [clientName, setClientName] = useState('');
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Factura guardada:', { clientName, amount });
    setClientName('');
    setAmount('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Registrar Factura</h2>
      <label>
        Cliente:
        <input type="text" value={clientName} onChange={(e) => setClientName(e.target.value)} />
      </label>
      <label>
        Monto:
        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
      </label>
      <button type="submit">Guardar Factura</button>
    </form>
  );
}

export default InvoiceForm;
