// src/pages/Clients.js
import React from 'react';
import ClientList from '../components/ClientList';
import ClientForm from '../components/ClientForm';

function Clients() {
  return (
    <div>
      <h1>Clientes</h1>
      <ClientList />
      <ClientForm />
    </div>
  );
}

export default Clients;
